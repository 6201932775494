import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Fade } from '@animations'
import {
  Container,
  FlexContainer,
  FlexItem,
  Gradient,
  Title,
  Label,
  Checklist,
} from '@UI'
import * as styles from './webOptions.module.scss'

const data = [
  {
    title: 'Brochure',
    label: 'Affordable and Quick to Launch',
    description:
      'Get your online presence up and running fast with a single page website built using a hand-crafted template. All templates are built to be highly performant, adhere to best practices, and utilize on-page SEO.',
    list: [
      'Single page website',
      'Built using a hand-made template',
      'Developed using on-page SEO best practices',
      'Communications directly into your inbox with a contact form',
    ],
  },
  {
    title: 'Deluxe',
    label: 'Set your business apart',
    description:
      "Get a custom designed website tailored to your business's exact needs. Capture leads with a contact form and live chat, and monitor your site's traffic with Google Analytics.",
    list: [
      "Custom design and built to your business's unique needs",
      'Google analytics set-up',
      'Communications directly into your inbox with a contact form',
      'Live chat installation',
    ],
  },
  {
    title: 'Blogger',
    label: 'Content generator',
    description:
      'Having a blog on your website drives more traffic to your site, resulting in better discovery and growth opportunities. This package has everything you need to publish your own blog posts, and get them seen on social media.',

    list: [
      'Content management system set-up',
      'Reusable blog post template',
      'Open graph and Twitter card set up',
    ],
  },
  {
    title: 'Marketer',
    label: 'Get the word out',
    description:
      'Unlock amazing growth potential with email marketing. Get a reusable landing page template for new offers and monitor your website’s traffic with the marketing set up.',

    list: [
      'Newsletter sign-up form',
      'Google analytics and Facebook Pixel set-up',
      'Content management system set-up',
      'Reusable landing page template',
    ],
  },
]

const WebOptions = () => {
  const [current, setCurrent] = useState(1)

  return (
    <Container sectionTop isSection className={styles.container}>
      <Gradient />
      <Container wrapper>
        <Fade>
          <Title className={styles.title} center>
            Flexible Packages for Your Unique Business Needs
          </Title>
        </Fade>
        <Container wrapperSmOnMd noPadding>
          <div className={styles.titlesContainer}>
            {data.map(({ title }, i) => (
              <motion.button
                key={title}
                onClick={() => setCurrent(i)}
                className={styles.button}
                animate={{ opacity: i === current ? 1 : 0.4 }}
                whileHover={{ opacity: i === current ? 1 : 0.7 }}
                whileFocus={{ opacity: i === current ? 1 : 0.7 }}
              >
                <Title sm>{title}</Title>
              </motion.button>
            ))}
          </div>

          <Fade key={data[current].label}>
            <Label className={styles.label}>{data[current].label}</Label>
          </Fade>

          <FlexContainer
            alignStart
            collapseOnMd
            className={styles.infoContainer}
          >
            <FlexItem className={styles.flexItem}>
              <Fade delay={0.3} key={data[current].description}>
                <p>{data[current].description}</p>
              </Fade>
            </FlexItem>

            <FlexItem className={styles.flexItem}>
              <Checklist
                data={data[current].list}
                key={data[current].list}
                listClass={styles.list}
                itemClass={styles.listItem}
              />
            </FlexItem>
          </FlexContainer>
        </Container>
      </Container>
    </Container>
  )
}

export default WebOptions
