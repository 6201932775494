import React from 'react'
import { Helmet } from 'react-helmet'
import { Layout, Seo, Cta } from '@global'
import {
  Hero,
  Intro,
  Benefits,
  Mobile,
  WebOptions,
  MaintenanceOptions,
  Testimonials,
  CaseStudies,
} from '@pages/home'

const IndexPage = () => (
  <Layout>
    <Seo />
    <Helmet encodeSpecialCharacters={false}>
      <meta name='p:domain_verify' content='ea2ed0ffc82ed16194772481cc22f4d8' />
      <script type='application/ld+json'>
        {`
        {
          "@context": "http://schema.org/",
          "@type": "WebPage",
          "name": "Affordable Websites for Small Businesses | Stellr Web | Cody, WY",
          "url": "https://www.stellrweb.com",
          "description": "Stellr Web builds affordable, conversion-focused websites for small businesses."
          }
        `}
      </script>
    </Helmet>
    <Hero />
    <Intro />
    <Benefits />
    <Mobile />
    <CaseStudies />
    <WebOptions />
    <MaintenanceOptions />
    <Testimonials />
    <Cta />
  </Layout>
)

export default IndexPage
