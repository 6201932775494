import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { HeroBlock } from '@blocks'

const Hero = () => {
  const image = useStaticQuery(graphql`
    query IndexHeroQuery {
      file(sourceInstanceName: { eq: "images" }, name: { eq: "hero" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  return (
    <HeroBlock
      image={image.file}
      titleTop='Modern, Affordable Websites'
      titleBottom='for Small Businesses'
      body='Your first step to growing your business online is ensuring your website is built to convert.'
      fullHeight={true}
    />
  )
}

export default Hero
