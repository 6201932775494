import React from 'react'
import { Card, Container, FlexContainer, FlexItem, Title } from '@UI'
import { Fade, Parallax, Slide } from '@animations'
import { Star } from '@images/icons'
import * as styles from './testimonials.module.scss'

const data = [
  {
    body: (
      <p>
        Stellr Web does what they say they will do, when they say they will do
        it, on time and without compromise. I just can’t say enough good things
        about Stellr Web. I would highly recommend them to any company or
        organization large or small...
      </p>
    ),
    name: 'Joe A.',
    linkText: 'All American Building Services LLC',
    href: 'https://www.aabscorp.com/',
  },
  {
    body: (
      <p>
        Stellr Web was able to design and launch my company's brand new website
        quickly and efficiently all within my budget. I would recommend Stellr
        Web to anyone who needs a modern website and reliable service.
      </p>
    ),
    name: 'L. M. Wagner',
    linkText: 'Cody Bookkeeping',
    href: 'https://www.codybookkeeping.net/',
  },
]

const Testimonials = () => {
  return (
    <Container section isSection className={styles.container}>
      <div className={styles.headlineTop}>
        <Slide bgColor='#232323' threshold={1}>
          <Title tag='p'>
            Stellr Web does
            <br />
            what they say they will do
          </Title>
        </Slide>
      </div>

      <Container wrapper className={styles.inner}>
        <FlexContainer collapseOnMd>
          <FlexItem>
            <Fade>
              <Title center className={styles.title}>
                What Clients Are Saying
              </Title>
            </Fade>
          </FlexItem>
          {data.map(({ body, name, linkText, href }, index) => (
            <FlexItem
              style={{ transform: `translateY(${index * 25}px)` }}
              key={name}
            >
              <Parallax offset={20 + index * 10}>
                <Card noHover light border className={styles.card}>
                  <div>
                    <div className={styles.cardStars}>
                      {[...Array(5).keys()].map((_, i) => (
                        <Star key={i} />
                      ))}
                    </div>
                    <div className={styles.cardBody}>{body}</div>
                    <p className={styles.cardName}>{name}</p>
                    <a href={href} target='_blank' rel='noreferrer'>
                      {linkText}
                    </a>
                  </div>
                </Card>
              </Parallax>
            </FlexItem>
          ))}
        </FlexContainer>
      </Container>

      <div className={styles.headlineBottom}>
        <Slide bgColor='#232323' threshold={1}>
          <Title tag='p'>Without Compromise</Title>
        </Slide>
      </div>
    </Container>
  )
}

export default Testimonials
