import React, { useState } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { motion } from 'framer-motion'
import { Container, Title } from '@UI'
import { Fade } from '@animations'
import { useCurrentWidth } from '@hooks'
import * as styles from './caseStudies.module.scss'

const imageVariants = {
  initial: {
    scale: 1,
    opacity: 1,
  },
  hover: {
    scale: 1.1,
    opacity: 0.1,
    transition: {
      opacity: {
        ease: [0.185, -0.01, 0, 1],
        duration: 0.7,
      },
      default: {
        ease: [0.185, -0.01, 0, 1],
        duration: 1.4,
      },
    },
  },
}

const textVariants = {
  initial: {
    opacity: 0,
  },
  hover: {
    opacity: 1,
  },
}

const CaseStudies = () => {
  const data = useStaticQuery(graphql`
    query IndexCaseStudiesQuery {
      allPrismicCaseStudies(filter: { data: { featured: { eq: true } } }) {
        edges {
          node {
            uid
            data {
              main_title {
                text
              }
              excerpt {
                text
              }
              services_list {
                list_item {
                  text
                }
              }
              main_bg_image {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)

  const [focused, setFocused] = useState(null)
  const width = useCurrentWidth()

  return (
    <Container sectionTop isSection>
      <Container wrapper>
        <Fade>
          <Title center>Recent Case Studies</Title>
        </Fade>
      </Container>

      <div className={styles.container}>
        {data.allPrismicCaseStudies.edges.map(({ node }, i) => {
          const study = node.data
          return (
            <motion.div
              key={node.uid}
              className={styles.section}
              initial='initial'
              animate={focused === i ? 'hover' : 'initial'}
              whileInView={width < 1000 && 'hover'}
              viewport={{ once: false, amount: 0.8 }}
              whileHover={width >= 1000 && 'hover'}
            >
              <motion.div
                variants={imageVariants}
                className={styles.image}
                transition={{ duration: 0.4 }}
              >
                <GatsbyImage
                  image={getImage(study.main_bg_image)}
                  alt=''
                  className={styles.image}
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                />
              </motion.div>
              <Container wrapperSm>
                <motion.div
                  variants={textVariants}
                  className={styles.textContainer}
                >
                  {study.services_list.map(({ list_item }) => (
                    <span key={list_item.text} className={styles.tag}>
                      {list_item.text}
                    </span>
                  ))}
                  <Title tag='h3' className={styles.studyTitle}>
                    {study.main_title.text}
                  </Title>
                  <p className={styles.studyBody}>{study.excerpt.text}</p>
                  <Link
                    to={`/case-studies/${node.uid}`}
                    className={styles.studyCallout}
                    onFocus={() => setFocused(i)}
                    onBlur={() => setFocused(null)}
                  >
                    Read about this project <span>&rarr;</span>
                  </Link>
                </motion.div>
              </Container>
            </motion.div>
          )
        })}
      </div>

      {/* <div className={styles.buttonContainer}>
				<Button>View All Case Studies</Button>
			</div> */}
    </Container>
  )
}

export default CaseStudies
