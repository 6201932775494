import React from 'react'
import { TitleBodyBlock } from '@blocks'

const Intro = () => {
  return (
    <TitleBodyBlock
      label='Web Design and Development in Cody, WY'
      title='Your Customers Are Looking for You'
    >
      <p>
        At Stellr Web, we are passionate about making effective web design
        solutions accessible to small businesses.
      </p>

      <p>
        We’d love to help you make a great first impression with your customers
        and grow your online presence, so get ready to turn your leads into
        customers with a conversion-focused, strategy-backed website built for
        the modern web.
      </p>
    </TitleBodyBlock>
  )
}

export default Intro
