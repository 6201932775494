import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { FullImageTextBlock } from '@blocks'
// import * as styles from './mobile.module.scss'

const Mobile = () => {
  const image = useStaticQuery(graphql`
    query IndexMobileQuery {
      file(
        sourceInstanceName: { eq: "images" }
        name: { eq: "gatsby-starter-wyoming-final-result" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  return (
    <FullImageTextBlock
      label='Mobile Responsive from the ground up'
      title='Reach People on All Their Favorite Devices'
      image={image.file}
    >
      <p>
        Today, about half of all internet traffic comes from mobile devices, so
        having a website that looks good across multiple screen sizes is a must.
        We build our websites mobile responsive from the very beginning.
      </p>
    </FullImageTextBlock>
  )
}

export default Mobile
