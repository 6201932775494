import React from 'react'
import { BokehCardsBlock } from '@blocks'
import { Rocket, Lock, Magnify } from '@images/icons'

const data = [
  {
    title: 'Fast',
    description:
      "Don't keep your users waiting. Deliver your website fast with content that is optimized for quick load times.",
    icon: <Rocket />,
  },
  {
    title: 'Secure',
    description:
      "You've worked hard to build a business people trust. They should be able to trust your website as well.",
    icon: <Lock />,
  },
  {
    title: 'Optimized',
    description:
      'On-page SEO best practices result in content made for people and easily found by Google.',
    icon: <Magnify />,
  },
]

const Benefits = () => {
  return (
    <BokehCardsBlock
      cardData={data}
      title='Give Your Visitors a Great Online Experience'
    />
  )
}

export default Benefits
